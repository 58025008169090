export const getQS = name => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
  const results = regex.exec(location.search)
  try {
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '))
  } catch (ex) {
    return ''
  }
}
